@import '../../../Layout/scss/variables';

.navContainer {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    transition: $mdc-animation-standard $mdc-elevation-transition-duration;
    display: flex;
    flex-direction: column;
    background-color: $white;
    @include mdc-elevation(8);

    nav {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
.sidenavWidth {
    width: 80px;
    flex: 0 0 80px;
    padding: 40px 0px;

    .btnContainer {
        padding: 0 18px;
    }
}
.sidenavWidthExpanded {
    width: 230px;
    flex: 0 0 230px;
    overflow-y: auto;

    .logo {
        h1 {
            max-width: 150px;
        }

        img {
            width:80%;
            height:auto;
        }
        
    }

    .btnContainer {
        padding: 0 20px;

        a {
            padding: 0 20px;
        }
    }
}

.btnLogo, .btnLogo:hover {
    text-decoration:none;
}

.sidenav {
    position: relative;
    transition: $mdc-animation-standard $mdc-elevation-transition-duration;
    

    
}

.navContainer {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    transition: $mdc-animation-standard $mdc-elevation-transition-duration;
    display: flex;
    flex-direction: column;
    background-color: $white;

    nav {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    
    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.1);
        background-color: #F5F5F5;
        border-radius: 6px;
    }

    &::-webkit-scrollbar
    {
        width: 6px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 6px;
        background-color: $grey-3;
    }
}

.linkContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
        display: flex;
        align-items: center;
        // border-radius: $radius;
        margin-bottom: 20px;
        
        // padding: 0 20px;
        height: 60px;
        overflow: hidden;
        // transition: box-shadow $mdc-elevation-transition-duration $mdc-animation-standard;
        transition: color $mdc-elevation-transition-duration $mdc-animation-standard;
        position: relative;
        // overflow: visible;

        background-repeat: no-repeat;
        background-position: 28px center;
        background-size: 24px;

        text-decoration: none;
        color: $grey-2;
        font-family: $myBriefFont;

        span {
            margin-left: 65px;

        }

        &:not(.link_logout) {
            margin-right: 20px;

            &:before {
                content: "";
                display: block;
                width: 5px;
                height: 0;
                position: absolute;
                left: 0px;
                background-color: transparent;
                border-radius: 0 5px 5px 0;
                background-color: #2E7FFB;
                transition: height $mdc-elevation-transition-duration $mdc-animation-standard;
            }
        }
        
        &:hover {
            color: $grey-1;
            &:before {
                height: 60%;
          }
        }

        &:global(.active) {
            color: $grey-1;
            &:before {
                height: 100%;
          }
        }
    }

    .link_logout {
        justify-content: center;
        max-width: 180px;
        font-weight:500;
        margin-bottom: 0;
        &:hover {
            color: #2E7FFB;

        }
        span {
            margin: 0;
        }
    }

    .link {
        &_dashboard {
            background-image: url('../../../assets/icon_nav/dashboard.png');
            &:hover, &:global(.active) {
                background-image: url('../../../assets/icon_nav/dashboard_hover.png');
            }
        }
        &_userList {
            background-image: url('../../../assets/icon_nav/users.png');
            &:hover, &:global(.active) {
                background-image: url('../../../assets/icon_nav/users_hover.png');
            }
        }
        &_groupList {
            background-image: url('../../../assets/icon_nav/groups.png');
            &:hover, &:global(.active) {
                background-image: url('../../../assets/icon_nav/groups_hover.png');
            }
        }
        &_statistics {
            background-image: url('../../../assets/icon_nav/stats.png');
            &:hover, &:global(.active) {
                background-image: url('../../../assets/icon_nav/stats_hover.png');
            }
        }
        &_account {
            background-image: url('../../../assets/icon_nav/users.png');
            &:hover, &:global(.active) {
                background-image: url('../../../assets/icon_nav/users_hover.png');
            }
        }
    }
}



.btnContainer {
    margin-bottom: 30px;
    overflow: hidden;
    transition: $mdc-animation-standard $mdc-elevation-transition-duration;
    
    a { 
        display: flex;
        padding: 0px 8px;
        height: 44px;
        align-items: center;
        justify-content: center;
        border-radius: 44px;
        border: 2px solid $grey-4;
        margin-bottom: 20px;
        color: $text-main;
        text-decoration: none;
        font-weight: bold;

        align-items: stretch;
        justify-items: stretch;

        transition: padding $mdc-animation-standard $mdc-elevation-transition-duration;


       

        &:global(.active), &:hover {
            border-color: $diamond;
            
        }

        div {
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            
            background-repeat: no-repeat;
            background-position: 0px center;
            background-size: 24px;

            overflow: hidden;

            span {
                margin-left: 35px;
            }
        }
    }

    .btn {
        &_newBrief {
            div {
                background-image: url('../../../assets/icon_nav/brief.png');
            }
        }
        &_newBriefOffer {
            div {
                background-image: url('../../../assets/icon_nav/brief_offer.png');
            }
        }
    }
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 30px;

    img {
        margin: 0px 5px;

        width:60px;
        height:auto;
    }

    h1 {
        font-size: 22px;
        color: $diamond;
        max-width: 0px;
        overflow: hidden;
        transition: max-width $mdc-animation-standard $mdc-elevation-transition-duration;

        span {

        }

        span:first-child {
            font-family: $myBriefFont;
        }
    }
}