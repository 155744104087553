@import '../../../Layout/scss/variables';

.description {
    @include card();
    padding: 20px;
    @include text-style('body');
    word-break: break-word;

    label {
        @include text-style('label');
        margin-bottom: 5px;
    }
}

.descriptionTitle {
    @include text-style('h4');
    margin-bottom: 10px;
    color:$grey-2;
}

.infosContainer {
    @include card();
    margin-bottom: 40px;

    label {
        @include text-style('label');
        
        line-height:24px;
        & + p, & + span {
            font-weight: bold;
            font-size:14px;
        }
    }
}

.infosTop {
    padding: 20px;
}

.infoLine {

    display: flex;

    label {
        margin-left: 0;
        margin-bottom: 5px;
        display: inline;
    }

    &:last-child {
        & > div {
            padding-top: 20px;
        }
    }

    & > div {
       
        // padding: 5px;

        &:first-child {
            padding-left: 0;
            padding-right: 10px;
            width: 48%;
        }


        &:last-child {
            position: relative;
            padding-right: 0;  
            padding-left: 15px;
            width: 52%;          

            &:before {
                content: '';
                width: 1px;
                background: $grey-4;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                
            }
        }
    }
}

.buttonSave {

    text-align:right;
    padding-top:15px;

    button {
        padding: 5px 10px;
        font-size:14px;
        margin-left: 10px;
    }
    
}

.infosBottomBar {
    padding: 20px;
    background-color: $off-white;
    font-size: 12px;
    border-radius: 0 0 12px 12px;

    & > div {
        
        &:last-child {
            &:before {
                content: none;
            }
        }
    }
}


.marketsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    // align-items: flex-end;
}

.markets {
    // justify-content: center;
}

.inputContainer{
    display: inline-block;
    margin-left: 18px;
}