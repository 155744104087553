@import '../../../Layout/scss/variables';

.stepsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 75px;
  justify-content: space-between;
  flex: 1;
  position: relative;

  &.closed {
    margin-bottom: 0;
  }
}

.stepsContainer {
  &:before {
    content: '';
    display: block;
    height: 0px;
    position: absolute;
    left: 2px;
    right: 2px;
    border: dotted 0.5px $grey-2;
    top: 22px;
  }
}

@include responsive {
  .stepsContainer {
    margin-bottom: 95px;
    order: 2;
    width: 100%;
  }
}