@import '../../Layout/scss/variables';

.brf-select-container{
    background: $white;
    padding: 10px 4px;
    border-radius: 12px;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.1);
    // margin-bottom: 12px;

    .brf-select__control{
        @include input();
        padding: 0;
        margin: 0;
    }
    
    .brf-select__value-container, .brf-select__value-container--is-multi{
        height: 40px;
        position: relative;
        .brf-select__input{
            width: 100%;

            input{
                height: 40px;
                margin: 0;
                position: absolute;
                top: -3px;
            }
        }
    }

    .brf-select__placeholder{

    }

    .brf-select__indicators{

    }

    .brf-select__menu{
        margin-top: 8px;
        .brf-select__menu-list, .brf-select__menu-list--is-multi{
            .brf-select__option{
                input{
                    width: auto;
                    height: auto;
                    display: inline-block;
                    margin: 0;
                }

                input[type=checkbox]{

                }
            }
        }
    }
}