@import '../../Layout/scss/variables';

.inlineEditorContainer {

}

.inlineEditorWrapper{
  display: flex;
  align-items: flex-end;

  .inlineEditor {
    margin-bottom: 0;
    
  }

}

.actionCtn {
  flex-shrink: 0;
  display: flex;
  
  button {
    height:40px;
    margin-left: 12px;
  }
}

.inlineEditorError {
  @include text-style('label');
  font-family: 'Avenir LT Pro';
  font-weight: normal;
  margin-top: 10px;
  color: red;
  
}