@import '../../../Layout/scss/variables';

.dropdown {
    button {
        padding: 3px 15px;
        border-radius: 50px;
        color: $text-main;
        font-weight: bold;
        font-size: 14px;
        box-shadow: none;
        border: 1px solid $grey-4;
        height: 32px;
        font-family: 'Avenir LT Pro';

        span:last-child {
            margin: 0;
            color: $text-main;
        }
    }
}