@import '../../Layout/scss/variables';

.formContainer {
  @include card();
  padding: 20px;
  max-width: 740px;
  width: 100%;
  margin: auto;

  .line {
    display: flex;
  }
  
  .inputContainer {
      margin-bottom: 20px;
      // ul{
      //   justify-content: left;
      // }
      ul + div {
        margin-bottom: 40px;
      }
  }

  .actionBtnContainer {
      display: flex;
      justify-content: flex-end;

      button{
        margin-right: 12px;
      }
  }

  label {
    // padding:5px 15px;
    // font-weight: bold;
    margin-left: 15px;
  }

  input[type="text"], input[type="search"] {
    padding: 3px 15px;
    border-radius: 50px;
    color: $text-main;
    font-weight: bold;
    font-size: 14px;
    box-shadow: none;
    border: 1px solid $grey-4;
    height: 32px;
    font-family: 'Avenir LT Pro';

    @include placeholder-style();

    span:last-child {
        margin: 0;
        color: $text-main;
    }
  }

  input[type="text"], input[type="search"] {
      padding: 8px 15px;
      font-family: 'Avenir LT Pro';
      font-size: 14px;
  }

  .uploader {
    button {
      color:white;
    }
  }
}

.actionBtnContainer {
  button {
    float:right;
   
  }
}

.titleInput {
  flex: 1 1;
  margin-right: 20px;
}

.liveDateInput {
  width: 190px;
  text-align: center;
}

#description {
  min-height: 122px;
}