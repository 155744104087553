.modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3D(-50%, -50%, 0);
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);

    h1 {
        font-size: 18px;
        font-weight: 600;
    }
}