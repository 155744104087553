@import '../../Layout/scss/variables';

label {
    @include text-style('label');
    margin-left: 20px;
    margin-bottom: 10px;
}

label[for="remember"] {
    display: inline-block;
}

input[name="remember"] {
    vertical-align: middle;
}

input:not([type="checkbox"]) {
    @include input();
}

.rdw-link-modal{
    height: auto;
}