@import '../../../Layout/scss/variables';

.attachmentsContainer {
    min-height: 200px;
    margin: 40px 0;
    img {
        max-height:24px;
        max-width:24px;
    }
    
}
.attachementContainerTitle {
    @include text-style('h4');
    margin-bottom: 10px;
    color:$grey-2;
}

.document {
    @include card();
    padding: 10px 20px 10px 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.docIcon {
    width: 35px;
    margin-right: 10px;

    img {
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
}
.docInfos {
    flex: 1;
    font-weight:500;
}
.docInfoLine {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}
.docDate, .docSize {
    font-weight:normal;
    @include text-style('small');
    line-height:unset;
}
.docPreviewBtn {
    width: 24px;
}
.docDownloadBtn {
    width: 24px;
    margin-left: 20px;
}

.docPreviewBtn:hover, .docDownloadBtn:hover {
    cursor:pointer;
}
.title {
    font-weight:700;
    line-height: 18px;
}

.dropZone {
    &:hover {
    cursor:pointer;
    }
}

:global(.filepond--drip) {
    background: rgba(0,0,0,0.31);
}

.actionBtnContainer {
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 10px;
    }
}