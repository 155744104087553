@import '../../../Layout/scss/variables';



.baseBtn {
    color: $white;
    display: inline-block;
    box-shadow: none;
    //height: 40px;
    //line-height: 40px;
    padding: 10px 20px;
    width: auto;
    text-align: center;
    font-weight: bold;
    border-radius: 20px;
    //padding: 0 20px;
    position: relative;
    z-index: 0;
    // font-size:$font-size-base*1.2;
    -webkit-appearance: none;
    background-color: transparent;
    transition: color ease .2s;
    text-transform: none;
    text-decoration: none;
    border:none;
    cursor: pointer;
    @include text-style('body');

    &:hover, &:focus {
        text-transform: none;
        text-decoration: none;
    }

    &::before {
        // background: $main;
        background: $diamond-gradient;
        border: 0 solid transparent;
        border-radius: 20px;
        // transition-timing-function: cubic-bezier(0, 2, 0, 0);
        transition: height .4s cubic-bezier(.31,3.4,.29,-0.15), top .4s cubic-bezier(.31,3.4,.29,-0.15);
        // transition-property: height, top, background-color;
        // transition-duration: 0.2s;
        content: '';
        width: 100%;
        height: 100%;
        display: block;   
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }

    &:disabled {
        cursor: not-allowed;
        color: #697A8D;
        &::before {
            background: $grey-gradient;
        }
    }
    

    &:not(:disabled):hover {
        color: $diamond;
        background-color: transparent;
        outline: none;

        &::before {
            background: $white;
            border-color: $diamond;
            border-width: 2px;
            height: 90%;
            top: 5%;
        }
    }
   
    // &.long {
    //     max-width: 345px;
    // }

    // &.thin {
    //     max-width: 120px;
    // }

    // &.big {
    //     max-width: 170px;
    //     height: 50px;
    //     border-radius: 30px;        
    //     padding: 0px 30px;

    //     &::before {
    //         border-radius: 25px; 
    //     }
    // }   
}

.secondaryButton:not(:disabled){
    color: $text-main;
    &::before{
        background: transparent;
        border: 2px solid $diamond;
    }

    &:not(:disabled):hover {
        color: $white;
        &::before {
            background: $diamond;
        }
    }
}

.tertiaryButton {
    color: $text-main;
    padding: 7px 10px;

    &:before {
        background: $white;
        border-color: $text-main;
        border-width: 2px;
    }

    &:not(:disabled):hover {
        color: $text-main;
        &::before {
            background: $white;
            border-color: $text-main;
        }
    }
}

%refresh {
    background-color: none;
    background-image: url('../../../assets/refresh.png');
    background-size:50%;
    background-position:50% 50%;
    background-repeat:no-repeat;
    border-radius: 16px;
    width:32px;
    height:32px;
    border-width: 1px;
    top: 0;
}

.refresh {
    width:32px;
    height:32px;
    border-radius: 15px;
    padding: 0;
    margin-top: 17px;

    &:before {
        @extend %refresh;
        border-color: $grey-4;
    }

    &:not(:disabled):hover {
        color: $text-main;
        &::before {
            @extend %refresh;
            background-color: white;
            border-color: $text-main;
            -webkit-animation:spin 3s linear infinite 50ms;
            -moz-animation:spin 3s linear infinite 50ms;
            animation:spin 3s linear infinite 50ms;
        }
    }    
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.linkButton{
    text-transform: none;
    text-decoration: none;
    &:hover{
        text-transform: none;
        text-decoration: none;
    }
}
