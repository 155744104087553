
@import './vars';

/** TEXT
---------------------------------------------------------*/
@mixin placeholder-style() {
    &::placeholder {
        font-size: 14px;
        color: #9197a3;
        font-weight: normal;
    }

    &:focus::placeholder {
        color: #bdc1c9;
    }
}

@mixin text-style($style) {
    @if $style=='h1' {
        font-family: $myBriefFont;
        font-size: 36px;
        line-height: 42px;
    }
    @else if $style=='h2' {
        font-family: $myBriefFont;
        font-size: 34px;
        line-height: 40px;
    }
    @else if $style=='h3' {
        font-size: 28px;
        line-height: 34px;
    }
    @else if $style=='h4' {
        font-size: 24px;
        line-height: 30px;
    }
    @else if $style=='h5' {
        font-size: 18px;
        line-height: 24px;
    }
    @else if $style=='body' {
        font-size: 14px;
        line-height: 20px;
    }
    @else if $style=='label' {
        font-size: 14px;
        line-height: 17px;
        font-weight: 900;
        color: $grey-2;
        display: block;
    }
    @else if $style=='small' {
        font-size: 12px;
        line-height: 15px;
        color: $grey-2;
        display: block;
    }
    @else if $style=='caption' {
        font-size: 11px;
        line-height: 17px;
    }
    @else {
        @error "$style must be 'h1' to 'h5', 'body', 'label' or 'caption', but received '#{$dp}'";
    }
}

/** EXTENDS
---------------------------------------------------------*/

@mixin mdc-elevation($dp) {
    @if $dp==0 {
        box-shadow: none
    }
    @else if $dp==1 {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .07), 0 2px 1px -1px rgba(0, 0, 0, .06), 0 1px 3px 0 rgba(0, 0, 0, .10)
    }
    @else if $dp==2 {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .07), 0 3px 1px -2px rgba(0, 0, 0, .06), 0 1px 5px 0 rgba(0, 0, 0, .10)
    }
    @else if $dp==3 {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .07), 0 3px 3px -2px rgba(0, 0, 0, .06), 0 1px 8px 0 rgba(0, 0, 0, .10)
    }
    @else if $dp==4 {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .07), 0 1px 10px 0 rgba(0, 0, 0, .06), 0 2px 4px -1px rgba(0, 0, 0, .10)
    }
    @else if $dp==6 {
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .07), 0 1px 18px 0 rgba(0, 0, 0, .06), 0 3px 5px -1px rgba(0, 0, 0, .10)
    }
    @else if $dp==8 {
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .07), 0 3px 14px 2px rgba(0, 0, 0, .06), 0 5px 5px -3px rgba(0, 0, 0, .10)
    }
    @else if $dp==12 {
        box-shadow: 0 12px 17px 2px rgba(0, 0, 0, .07), 0 5px 22px 4px rgba(0, 0, 0, .06), 0 7px 8px -4px rgba(0, 0, 0, .10)
    }
    @else if $dp==16 {
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .07), 0 6px 30px 5px rgba(0, 0, 0, .06), 0 8px 10px -5px rgba(0, 0, 0, .10)
    }
    @else if $dp==24 {
        box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .07), 0 9px 46px 8px rgba(0, 0, 0, .06), 0 11px 15px -7px rgba(0, 0, 0, .10)
    }
    @else {
        @error "$dp must be 1,2,3,4,6,8,12,16 or 24, but received '#{$dp}'";
    }
}

// 
@mixin card() {
    display: block;
    border-radius: $radius;
//  overflow: hidden;
    background-color: $white;
    @include mdc-elevation(1)
}

// 
@mixin selectableCard() {
    @include card();
    transition: box-shadow $mdc-elevation-transition-duration $mdc-animation-standard;
    &:hover {
        @include mdc-elevation(8)
    }   
}

// inputs
@mixin input(){
    height: 40px;
    border-radius: 40px;
    border: 2px solid $grey-4;
    display: flex;
    background-color: $white;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;

    @include placeholder-style();
}

// Page title
@mixin pageTitle(){
    @include text-style('h1');
    display: inline-block;
    margin-right: 30px;
    // margin-bottom: 20px;
}

/** RESPONSIVE
---------------------------------------------------------*/
$standard-bp: 1024px;
$small-bp: 720px;

@mixin responsive($size: $standard-bp) {
    @media (max-width: $size) {
        @content;
    }
}