@import '../../../Layout/scss/variables';


.stepname {
  position: absolute;
  top: 100%;
  color: $diamond;
  height: 18px;  
  white-space: nowrap;
  text-align: center;
  

  &:before {
    content: '';
    display: block;
    background-image: url('../../../assets/dash.svg');
    background-size: 60px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center top;
    padding-top:5px;
  }
  
}

.subStep {
  height: 20px;
  width: 20px;
  display: inline-flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: $white;
  font-size: 12px;
  flex: 1 0 20px;
  cursor: help;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;


  &.status {
    &_wait {
      background-color: $grey-2;

      .stepname {
        color: $grey-2;
  
        &:before {
          background-image: url('../../../assets/dash-wait.svg');
        }
      }
    }

    &_process, &_finish {
      background-color: $diamond;
      background-color: $diamond;
    }

  }


  &.icon {
    &_refused {
      background-image: url('../../../assets/icon_stepbar/refused.png');
    }
    &_check {
      background-image: url('../../../assets/icon_stepbar/check.png');
    }
    &_creation {
      background-image: url('../../../assets/icon_stepbar/creation.png');
    }
    &_dispatch {
      background-image: url('../../../assets/icon_stepbar/dispatch.png');
    }
    &_in-progress {
      background-image: url('../../../assets/icon_stepbar/in-progress.png');
    }
    &_next {
      background-image: url('../../../assets/icon_stepbar/next.png');
    }
    &_task {
      background-image: url('../../../assets/icon_stepbar/task.png');
    }
    &_waiting {
      background-image: url('../../../assets/icon_stepbar/waiting.png');
    }
  }

}

.icon {
  width: 13px;
  height: 13px;
}

@include responsive {
  .subStep {
    flex-grow: 0;
    &:first-child {
      margin-left: 0;
    }
  }
  .stepname {
    max-width: 70px;
    white-space: normal;
  }
}
