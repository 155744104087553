@import '../../../Layout/scss/variables';

.chipElement {
    display: inline-block;
    padding: 5px 5px 2px 5px;
    border: solid 1px $grey-2;
    border-radius: 5px;
    margin-right: 5px;
    position: relative;
    font-size: 11px;
    text-align: center;
}

.chipValue {
    text-transform: uppercase;
    color: $grey-2;
}

.deleteChipBtn {
    text-align: center;
    width: 14px;
    display: inline-block;
    position: relative;
    left: -4px;
    cursor: pointer;
}

.addChipBtn{

}