@import './Layout/scss/index';

@font-face {
  font-family: 'Avenir LT Pro 65';
  src: url('./assets/fonts/AvenirLTPro-BlackOblique.eot');
  src: url('./assets/fonts/AvenirLTPro-BlackOblique.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirLTPro-BlackOblique.woff2') format('woff2'),
      url('./assets/fonts/AvenirLTPro-BlackOblique.woff') format('woff'),
      url('./assets/fonts/AvenirLTPro-BlackOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir LT Pro';
  src: url('./assets/fonts/AvenirLTPro-Roman.eot');
  src: url('./assets/fonts/AvenirLTPro-Roman.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirLTPro-Roman.woff2') format('woff2'),
      url('./assets/fonts/AvenirLTPro-Roman.woff') format('woff'),
      url('./assets/fonts/AvenirLTPro-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir LT Pro 65';
  src: url('./assets/fonts/AvenirLTPro-Black.eot');
  src: url('./assets/fonts/AvenirLTPro-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/AvenirLTPro-Black.woff2') format('woff2'),
      url('./assets/fonts/AvenirLTPro-Black.woff') format('woff'),
      url('./assets/fonts/AvenirLTPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@import './Layout/scss/variables';

body {
  margin: 0;
  padding: 0;
  font-family: 'Avenir LT Pro', apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text-main;
  font-size: 14px;
}

a, a:visited, a:active {
  color: $text-main;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.router-container {
  display: flex;
}

.route-wrapper {
  position: relative;
  // width: 100%;
  flex: 0 1 100%;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
}