@import '../../../Layout/scss/variables';

.item {
    @include selectableCard();
    border-radius: 20px;
    margin-bottom: 20px;
    text-transform: none;
    text-decoration: none;
    overflow: visible;
    position: relative;
    margin-bottom:40px;

    &:visited {
        color: inherit;
    }

    
}

.itemSmall {
    .topInfoBar {
        padding-bottom: 10px;
        h2 {
            flex-basis: 100%;
            margin-bottom: 10px;
            
        }
    }
}

/* --- MAIN BAR --- */
.topInfoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    flex-wrap: wrap;

    h2 {
        @include text-style('h5');
        flex-basis: 600px;
        flex: 2 0;
        font-weight:700;
    }
}

.state {
    flex: 1 0;
    color: $grey-1;
    line-height: 20px;
    padding: 0 20px;
}

.liveDate {
    text-align: center;
    @include text-style('body');
    color: $diamond;
    font-weight:700;
}




.ok {
    background-color: #5EBB30;
}



/* --- BOTTOM BAR --- */
.bottomInfoBar {
    background-color: $off-white;
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 0 0 20px 20px;
    font-size: 12px;
    //background-color:red;
    
    .infoId {
        width: 50px;
        margin-right: 30px;
        font-weight: bold;
        //background-color:green;
        order: 1;
        flex-shrink: 0;
    }

    .infoCreation {
        order: 2;
        margin-right: 30px;
        white-space: nowrap;
        line-height:16px;
        //background-color:blue;
    }

    .marketsList {
        order: 3;
        //flex: 1 1;
        //flex-wrap:nowrap;
        //background-color:orange;
        img:not(:last-child) {
            margin-right: 10px;
        }
    }

    .infoLeft {
        order:1;
    }
    .infoRight {
        order:2;
    }

    .infoLeft, .infoRight {
        display: flex;
        align-items: center;

    }
    .infoRight {
        
        color: $text-main;
        button{
            width: 118px;
            height: 28px;
            border-radius: 100px;
            border: none;
            background: $diamond-gradient;
            font-weight:500;
            color:white;
            &:hover{
                background: $white;
                border: solid 2px #3269FF;
                cursor: pointer;
                color:#3269FF;
            }
            font-family: 'Avenir LT Pro';
        }
        & > span, & > label {
            margin-bottom: 0;
            margin-right: 12px;
        }

        
    }
    
    .dot, .marketsList {
        margin-right: 20px;
    }
    
    
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
    }
}



.strong {
    font-weight: 600;
}

.infoType {
    width: 30px;
    margin-right: 10px;
    font-weight: bold;
}


.infoId, .state {

    font-weight:700;
    
    img {
        height:20px;
        width:20px;
        vertical-align: middle;
        margin: 0 7px 0 0;
    }
}



/* --- PRIORITY CHIP --- */
.pillsCtn {
    height: 20px;
    position: absolute;
    top: -10px;
    left: 20px;
    display: flex;
}

.pill {
    height: 20px;
    font-size: 11px;
    border-radius: 50px;
    text-transform: uppercase;
    display: inline-block;
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-weight: bold;     
    margin-right: 10px;
}

.priority-3 {
    background: $orangepink-gradient;
    color: $white;
}

.priority-2 {
    background: $brief-state_medium;
    color: $white;
}

.urgent {
    background: $carbon;
    color: $white;
}

.iconCircle {
    
    &_closed {
        background-image: url('../../../assets/icon_stepbar/closed.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_refused {
        background-image: url('../../../assets/icon_stepbar/refused.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_check {
        background-image: url('../../../assets/icon_stepbar/check.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_creation {
        background-image: url('../../../assets/icon_stepbar/creation.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_dispatch {
        background-image: url('../../../assets/icon_stepbar/dispatch.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_in-progress {
        background-image: url('../../../assets/icon_stepbar/in-progress.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_next {
        background-image: url('../../../assets/icon_stepbar/next.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_task {
        background-image: url('../../../assets/icon_stepbar/task.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
    &_waiting {
        background-image: url('../../../assets/icon_stepbar/waiting.png');
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $diamond;
        display: inline-block;
        background-size: cover;
        vertical-align:middle;
        margin-right:10px;
    }
}

@include responsive {
    .bottomInfoBar {
        flex-direction: column;

        .infoCreation {
            white-space: normal;
        }
    }
    .infoRight {
        &:not(:empty) {
            margin-top: 10px;
        }
        label {
            margin-left: 0;
        }
    }

}