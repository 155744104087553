@import '../../../Layout/scss/variables';

.wrapper {
  background-color: $white;
  border: 2px solid $grey-4;
  border-radius: 10px;
  margin-bottom: 10px;
}

.editor {
  // background-color: green;
  padding: 0 10px 10px 10px;

}

.toolbar {
  // background-color: blue;
  border-width: 0 0 1px 0 !important;
  border-radius: 8px 8px 0 0 !important;

  :global(.rdw-option-wrapper) {
    border: 0;
    &:hover {
      @include mdc-elevation(2)
    }

    &:global(.rdw-option-active) {
      @include mdc-elevation(1)
    }
  }
}