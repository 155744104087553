@import '../../../Layout/scss/variables';

.searchbarContainer {
    height: 40px;
    border-radius: 40px;
    border: 1px solid $grey-4;
    display: flex;
    background-color: $white;
    flex: 1;
}

.searchbarInput {
    padding: 24px;
    border: 0;
    height: auto !important;
    border-radius: 40px;
    background: none;
    padding: 10px;
    width: 100% !important;
    border: none !important;
    margin: 0 !important;
    font-family: 'Avenir LT Pro';
    font-size: 14px;

    @include placeholder-style();
}



.searchButton {
    background:none;
    width: 40px;
    height: 40px;
    border: none;
    padding: 0;

    .searchIcon {
        background-color:white;
        background-image: url('../../../assets/search.png');
        border: none;
        padding: 0;
        width: 20px;
        height: 20px;
        background-size:cover;
        margin:0 0 3px 15px;  
    }

    div {
        margin:0 0 0 15px;  
    }
}

.searchButton:hover {
    //cursor:pointer;
}

.searchOverlay {
    background-color: rgba($off-white, 0.75);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;    
    z-index: 1;
    display: flex;
    justify-content: flex-end;
}

.resultContainer {
    background-color: $white;
    width: 500px;
    padding: 40px 20px 20px 20px;
    overflow: auto;
}

.resultActionContainer {
    display: flex;
    margin-bottom: 40px;
}

// .closeBtnContainer {
//     display: flex;
//     justify-content: center;
// }

// .closeBtn {
//     border: none;
//     margin: 0;
//     padding: 0;
//     overflow: visible;
//     background: transparent;
//     color: inherit;
//     font: inherit;
//     line-height: normal;
//     -webkit-font-smoothing: inherit;
//     -moz-osx-font-smoothing: inherit;
//     -webkit-appearance: none;

//     height: 30px;
//     width: 30px;
//     border-radius: 40px;
//     margin-bottom: 20px;
//     border: 1px solid $text-main;
//     font-weight: bold;

//     &:hover {
//         cursor: pointer;
//         border-color: $grey-3;
//         color: $carbon;
//     }

// }

.totalResults {
    color:$dark-blue;
    padding: 0 5px 15px 0;
    text-align:right;
}