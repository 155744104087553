@import '../../../Layout/scss/variables';

.stepContainer {
  display: flex;
  flex-direction: row;
  z-index: 1;
  align-items: stretch;
  height: 44px;
}

.stepItem {
  border-radius: 44px;
  padding: 2px;
}

.stepContent {
  @include card();
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;

}

.waitStatus {
  cursor: pointer;
  color: $grey-2;
  .stepIcon {
    background-color: $grey-2;
  }
}

.processStatus {
  background: $diamond-gradient;
  font-weight: bold;
  color: $diamond;
  .stepIcon {
    background-color: $diamond;
  }
}

.finishStatus {
  cursor: pointer;
  color: $diamond;
  .stepIcon {
    background-color: $diamond;
  }
}

.stepIcon {
  height: 20px;
  width: 20px;
  display: inline-flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: $white;
  font-size: 12px;
  font-weight: bold;
  flex: 1 0 20px;
}

.childrenContainer {
  display: flex;
  align-items: center;
  border: $grey-4 solid;
  border-width: 1px 1px 1px 0px;
  border-radius: 0px 50px 50px 0;
  padding-right: 10px;
}

@include responsive {
  .stepContainer {
    flex-direction: column;
    .childrenContainer {
      padding: 27px 20px 5px 20px;
      position: relative;
      top: -22px;
      z-index: -1;
      border-width: 0px 1px 1px 1px;
      border-radius: 0px 0px 22px 22px;
      justify-content: center;
    }

    &:not(.stepContainerExpanded) {
      .stepIcon {
        margin-right: 0;
        + span {
          display: none;
        }
      }
    }
  }
}