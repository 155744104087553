@import '../../Layout/scss/variables';

.pageHeaderBtnContainer {
    & > button {
        margin-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .editBtn {
        margin-right: 60px;
        &:after {
            content: '';
            display: block;
            background-color: $grey-4;
            width: 2px;
            position: absolute;
            right: -30px;
            top: 0;
            bottom: 0;
        } 
    }
}

.liveDateLabel {
    position: absolute;
    top: -24px;
    left: -12px;
}

.workflowContainer {
    margin-bottom: 20px;
}

.workFlowContainerBorder {
    border: 1px solid $grey-4;
    border-radius: 10px;
    padding: 32px 20px 20px 20px;
}

.workflow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.workflowActionContainer {
    & ~ .workflowActionContainer {
        margin-top: 20px;
    }

    p {
        padding : 7px 4px;
    }
}

.worflowBtnContainer {
    display: flex;
    justify-content: flex-end;

    & > button {
        margin-left: 20px;
    }
}

.commentActionContainer {
    button {
        float: right;
        margin-left:20px;
    }
}

.container {
    display: flex;
    margin-top: 80px;
}

.left {
    width: 40%;
    margin-right: 25px;
}

.right {
    width: 60%;
    margin-left: 25px;
}

.stepContainer {
    justify-content: space-between;
}


.historySectionHeader {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    h4 {
        @include text-style('h4');
        font-size: 26px;
        color:$grey-2;
    }
}

.historySectionHeaderTop {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.liveDateCtn {
    margin-left: 5%;
    position: relative;
}

.liveDateActionWrapper {
    margin-bottom: 20px;
    text-align: right;
}

.liveDateActionCtn {
    button {
        margin-left: 10px;
    }
}

.liveDateError, .liveDateActionCtn {
    margin-top: 10px;
}

.liveDate {
    @include card();
    @include text-style('h5');
    color: $diamond;
    height: 40px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    max-width: 200px;
    font-weight:500;
    font-weight: bold;
    
    input{
        border: none !important;
        margin: 0 !important;
        color: $text-main;
        @include text-style('h5');
        font-weight: 500;
    }

    :global(.react-datepicker__navigation) {
        margin-top: 3px;
    }
}

:global(.react-datepicker-popper) {
    z-index: 100;
}

.Modal {
    // position: absolute;
    // top: 40px;
    // left: 20%;
    // right: 40px;
    // bottom: 40px;
    // background-color: #FFF;
    // display: none;
    h1{
        margin-bottom: 22px;
    }

}

.stepsWrapper{
    display: flex;
    margin-bottom: 22px;
}

.stepContainerFrom{
    flex-grow: 1;
   >div{
    border: solid 1px $grey-4;
    margin-right: 12px;
    border-radius: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 8px;
   }
}

.stepContainerTo{
    flex-grow: 2;
    >div{
        border-radius: 12px;
        border: solid 1px $grey-4;
        margin-right: 12px;
        height: 32px;
        button{
            border: none;
            box-shadow: none;
            border-radius: 12px;
            width: 100%;
            height: 29px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

@include responsive {
    .workflow {
        flex-direction: column;
    }

    .liveDateCtn {
        width: 100%;
        margin-left: 0;
        margin-bottom: 30px;
        order: 1;
    }

    .liveDate {
        margin: auto;
    }

    .container {
        flex-direction: column;
    }

    .left, .right {
        width: 100%;
        margin: auto;
        max-width: 740px;
    }

}