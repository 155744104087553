@import '../../Layout/scss/variables';

html, body {
    // height: 100%;
    // overflow-x: hidden;
}

.layout {
    // display: flex;
}

main {
    flex: 1;
    padding: 40px;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
}

body {
    background-color: $off-white;
}

.search {
    position: absolute;
    right: 10px;
    margin: 10px 0 0 0;
}

// Login Layout

.loginLayout {
    background-image: url('../../assets/login_background.jpg');
    background-size: cover;
    background-position: center;
    padding: 40px;

    h1 {
        color:$dark-blue;
        @include text-style('h1');
        padding: 5px 0;
        font-weight:700;
    }

    h2 {
        @include text-style('h5');
        padding: 5px 0;
        color:$grey-1;
        font-weight:500;
    }

    form {
        padding: 20px 0;

    }

    .submitLogin {
        text-align:right;
        color:red;
    }

}

.submitLogin {
    text-align:right;
    color:red;
}

.loginContainer {
    @include card();
    padding: 40px;
    width: 500px;
    max-width: 100%;
    height: 100%;
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
outline: none;
}

.react-datepicker-wrapper input, .react-datepicker-ignore-onclickoutside {
    font-family:$myBriefFont;
}