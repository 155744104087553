@import '../../../Layout/scss/variables';

.chipsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    // justify-content: center;

    li {
        margin: 5px 0;
    }
    
}

.addChipBtn {
    margin-top: 10px;
    button {
        display: inline-block;
        padding: 0 12px;
        // width: 20px;
        height: 20px;
        border: solid 1px $grey-2;
        border-radius: 5px;
        background-color: transparent;
        font-family: $myBriefFont;
        // &::after{
        //     content: "+";
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     text-align: center;
        // }

        svg {
            display: none;
        }
    }
}