
/** COLORS
---------------------------------------------------------*/
$dark-blue: #253b56;
$text-main : $dark-blue;

$home-sky: #44d6ff;
$diamond: #277BFF;

$brief-state_red: #ff301c;
$brief-state_medium: #ffa100;

// Grey
$carbon: #090909;
$grey-1: #4C5A67;
$grey-2: #8A9BAE;
$grey-3: #B2B2B2;
$grey-4: #E6E6E6;
$off-white: #F7F7F7;
$white: #FFFFFF;
 
//  $gold-gradient : linear-gradient(to left,#DFBC5D, #C08718);
//  $green-gradient : linear-gradient(to left,#AAEC5D, #6BC63B);
//  $purple-gradient : linear-gradient(to left,#E06092, #915DB5);
//  $gray-gradient: linear-gradient(to left, #e6e6e6, #caced6);
$diamond-gradient: linear-gradient(45deg, #3269FF 0%, #00B5FF 100%);
$orangepink-gradient: linear-gradient(45deg, #FF7D00 0%, #FF4D54 100%);
$green-gradient: linear-gradient(45deg, #50B61E 0%, #C1F86D 100%);
$gold-gradient : linear-gradient(45deg, #C08718 0%, #DFBC5D 100%);
$honey-gradient : linear-gradient(45deg, #FDE689, #FFA532);
$green-gradient : linear-gradient(45deg, #6BC63B, #AAEC5D 100%);
$purple-gradient : linear-gradient(45deg, #915DB5, #E06092 100%);
$grey-gradient: linear-gradient(45deg, #CACED6,  #E6E6E6 100%);


$myBriefFont : 'Avenir LT Pro 65', apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;


/** OTHER
---------------------------------------------------------*/

$radius: 10px;
$shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.1);


/** ANIMATION (from: material-components-web)
---------------------------------------------------------*/
$mdc-animation-deceleration: cubic-bezier(0, 0, .2, 1) !default;
$mdc-animation-standard: cubic-bezier(.4, 0, .2, 1) !default;
$mdc-animation-acceleration: cubic-bezier(.4, 0, 1, 1) !default;
$mdc-animation-sharp: cubic-bezier(.4, 0, .6, 1) !default;

$mdc-elevation-transition-duration: 280ms !default;