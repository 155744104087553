@import '../../../Layout/scss/variables';

.historyItem {
  @include card();
  padding: 20px;
  margin-bottom: 50px;
  flex: 1 0;
}

.comment {
  .updateInfo {
    
  }
  line-height:20px;
  padding:7px;
}

.updateDescription {

  .before {
    color:$grey-4;
    text-decoration:line-through;
  }

  word-break: break-word;

  ul {    
    list-style:circle;
    padding: 0 10px 10px 25px;
  }
  li {
    line-height:20px;
  }
  .updateInfo {
    //font-style:italic;
    font-weight:500;
    
  }

  img {
    width: 25px;
    height: 25px;
    object-fit: contain;
    vertical-align:middle;
    margin-right:5px;
  }

}

.attachment {
  font-weight:700;
}

.stateBloc {
  border-radius: $radius;
  @include text-style('body');
  background-color:$off-white;
  color:$grey-1;
  font-weight:700;
  padding:7px 12px;

  & + .comment {
    margin-top: 10px;
  }
}

.descAction {
  font-weight:normal;
  color:$grey-2;
  font-size:12px;
}

.newState {
  height: 40px;
  border-radius: 5px;
  background-color: $off-white;
  display: flex;
  align-items: center;
  padding: 5px;
}

.logHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.username {
  font-weight: bold;
}
.date {
  @include text-style('label');
  display: inline-block;
  text-align: right;
}

.day {
  @include text-style('label');
  display: inline-block;
}

.time {
  margin-left: 10px;
}

.historyItemContainer {
  display: flex;
  position: relative;

  &:not(:last-child):before {
    content: '';
    display: block;
    width: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    border: dotted 0.5px $grey-1;
    left: 19px;
  }
}
.stepNumber {
  width: 40px;
  height: 40px;
  border: 13px solid $off-white;
  background-color: $grey-2;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-weight: bold;
  margin-right: 10px;
  position: relative;
  // box-shadow: $shadow;
}

.documentSize {
  font-weight:500;
  font-size:11px;
  color:$grey-2;
}

.iconCircle {
    
  &_closed {
      background-image: url('../../../assets/icon_stepbar/closed.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_refused {
      background-image: url('../../../assets/icon_stepbar/refused.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_check {
      background-image: url('../../../assets/icon_stepbar/check.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_creation {
      background-image: url('../../../assets/icon_stepbar/creation.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_dispatch {
      background-image: url('../../../assets/icon_stepbar/dispatch.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_in-progress {
      background-image: url('../../../assets/icon_stepbar/in-progress.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_next {
      background-image: url('../../../assets/icon_stepbar/next.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_task {
      background-image: url('../../../assets/icon_stepbar/task.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
  &_waiting {
      background-image: url('../../../assets/icon_stepbar/waiting.png');
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $diamond;
      display: inline-block;
      background-size: cover;
      vertical-align:middle;
      margin-right:10px;
  }
}