.loader {
    background-image: url('../../../assets/loader.png');
    width: 20px;
    height: 20px;
    background-size: cover;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
    margin: 15px auto;
}

.loader[type="button"] {
    background-image: url('../../../assets/loader-white.png');
    margin: 0 auto;
    width: 15px;
    height: 15px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }