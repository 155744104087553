@import '../../../Layout/scss/variables';

.pageHeader {
    margin-bottom: 45px;
    position: relative;

}

.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
   
}
.bottomHeader {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include text-style('label');
    font-weight:500;
    color:$grey-1;

    img {
        vertical-align:middle;
    }

    h2 {
        font-size:15px;
        font-weight:700;
    }
}

.pageTitle {
    @include pageTitle();
    // width: 100%;
    // padding-top:20px;
    // margin-bottom: 5px;
}

.pageTitleEdit {
    input {
        font-size: 18px;
        font-weight: bold;
    }
}

.headerSearchbar {
    order: 2;
}

.hasChildren {

    .pageTitle, .pageTitleEdit {
        order: 3;
        margin-top: 20px;
        margin-right: 0;
        width: 100%;
    }

    .topContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 50px;
    }
}